<template>
    <div>
        <b-row>
            <b-col>
                <h4>Quote View - {{padResult(selectedQuote.quoteNumber)}}</h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4">
                <label class="bold">Client</label>
                <label>{{selectedQuote.companyName}} - {{selectedQuote.clientName}}</label>
            </b-col>
            <b-col cols="4">
                <label class="bold">Date</label>
                <label>{{selectedQuote.quoteDate | dateFilter}}</label>
            </b-col>
            <b-col cols="4">
                <label class="bold">Ticket Number</label>
                <label>{{selectedQuote.ticketReferenceNo}}</label>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4">
                <label>Purchase Order Number <span v-if="this.selectedQuote.statusId == 4" class="text-red">*</span></label>
                <b-form-input v-model="selectedQuote.purchaseOrderNumber" :disabled="!isQuoteEditable || ogStatusSelection !== 1 && this.selectedQuote.statusId != 4"></b-form-input>
            </b-col>
            <b-col cols="4">
                <label>Type of Document</label>
                <b-form-select v-model="selectedQuote.quoteTypeId" :disabled="!isQuoteEditable || (state === 'loadingData' || ogStatusSelection !== 1)" @change="checkStatusList()" >
                    <b-form-select-option v-for="(item, index) in typeList" :key="index" :value="item.id">{{item.description}}</b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col cols="4">
                <label>Quotation Status</label>
                <b-form-select v-model="selectedQuote.statusId" :disabled="!isQuoteEditable || (state === 'loadingData' || ogStatusSelection === 4 || ogStatusSelection === 2 || ogStatusSelection === 3 || wasHandled)">
                    <b-form-select-option v-for="(item, index) in statusList" :key="index" :value="item.id">{{item.description}}</b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        <b-row v-if="selectedQuote.statusId === 2">
            <b-col cols="12">
                <label>Reason for Rejection</label>
                <b-form-input v-model="selectedQuote.note" :disabled="selectedQuote.statusId !== 2 || ogStatusSelection ===2"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4">
                <label>Business Units</label>
                <b-form-select v-model="selectedQuote.businessUnitId" :disabled="!isQuoteEditable || ogStatusSelection !== 1">
                    <b-form-select-option v-for="(item, index) in businessUnits" :key="index" :value="item.key">{{item.value}}</b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="text-right">
                <b-button variant="primary" squared @click="openAddItemModal()" class="mr-2" :disabled="!isQuoteEditable || ogStatusSelection !== 1">Add Item (+)</b-button>
                <b-button variant="primary" squared @click="download()">
                    <b-spinner small v-if="state === 'loading'"></b-spinner> Export</b-button>
            </b-col>
        </b-row>

        <b-row class="mt-4">
            <b-col cols="12">
                <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns" :busy="tableData.isLoading"
                sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>
                    <template #cell(price)="row">
                        <b-input-group prepend="R">
                            <b-form-input v-model="row.item.price" type="number" :disabled="ogStatusSelection !== 1"></b-form-input>
                        </b-input-group>
                    </template>
                    
                    <template #cell(quantity)="row">
                        <b-form-input v-model="row.item.quantity" type="number" :disabled="ogStatusSelection !== 1"></b-form-input>
                    </template>
                    
                    <template #cell(vatPerc)="row">
                        <!-- <b-form-checkbox
                        v-model="row.item.vatIncluded"
                        value="true"
                        unchecked-value="false"
                        ></b-form-checkbox> -->

                        <b-input-group append="%">
                            <b-form-input v-model="row.item.vatPerc" type="number" :disabled="ogStatusSelection !== 1"></b-form-input>
                        </b-input-group>

                    </template>

                    <template #cell(total)="row">
                        R {{addCommas(totalLineItem(row.item))}}
                    </template>
                    
                    <template #cell(actions)="row">
                        <b-row align-h="end">
                            <b-button @click="removeStockItem(row.item)" variant="red" size="sm" class="ml-2" :disabled="ogStatusSelection !== 1">Remove
                            </b-button>
                        </b-row>
                    </template>

                    
                    <template slot="bottom-row">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <p class="mb-0 bold">Subtotal</p>
                            <p class="mb-0 bold">VAT</p>
                            <p class="mb-0 bold">Total</p>
                        </td>
                        <td class="text-right">
                            <p class="mb-0">R {{addCommas(subtotal.toFixed(2))}}</p>
                            <p class="mb-0">R {{addCommas(calcvat().toFixed(2))}}</p>
                            <p class="mb-0">R {{addCommas(total.toFixed(2))}}</p>
                        </td>
                        <td></td>
                    </template>

                </b-table>
            </b-col>
        </b-row>
        <b-row align-h="center" >
            <b-col cols="12" class="text-right">
                <b-button variant="red" squared class="mr-2" @click="returnToPrevious()">Back</b-button>
                <b-button variant="primary" squared @click="save()" :disabled="!isQuoteEditable">
                    <b-spinner small v-if="state === 'loading'"></b-spinner> Save
                </b-button>
            </b-col>
        </b-row>

        
        <b-modal id="addItem-modal" hide-footer @close="closeModal()" size="xl">
                    <b-row>
                        <b-col>
                            <h4>Product Search</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label>Ticket Number</label>
                            <b-form-input v-model="search.description"></b-form-input>
                        </b-col>
                        <b-col cols="4">
                            <label>Date Created From</label>
                            <b-form-datepicker v-model="search.dateCreatedFrom"></b-form-datepicker>
                        </b-col>
                        <b-col cols="4">
                            <label>Date Created To</label>
                            <b-form-datepicker v-model="search.dateCreatedTo"></b-form-datepicker>
                        </b-col>
                    </b-row>
                    <hr class="mx-3" />
                    <b-row>
                        <b-col cols="12" class="text-right">
                            <b-button variant="red" squared class="mr-2" @click="clearFilter()">Cancel</b-button>
                            <b-button variant="primary" squared @click="searchResults()">Search</b-button>
                        </b-col>
                    </b-row>
            <b-row class="mt-4">
                <b-col cols="12">
                    <b-table striped hover :items="productsTableData.dataSource" :fields="productsTableData.tableColumns" 
                        :busy="productsTableData.isLoading" @row-clicked="addStockItemRow" sort-icon-left>
                        <template #table-busy>
                            <div class="text-center my-2">
                                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                            </div>
                        </template>

                        <template #cell(createdDate)="row">
                            {{row.item.createdDate | dateTimeFilter}}
                        </template>

                        <template #cell(priceExVat)="row">
                            R {{row.item.priceExVat}}
                        </template>

                        
                        <template #cell(actions)="row">
                            <b-row align-h="end">
                                <b-button @click="addStockItemRow(row.item)" class="btn-icon" size="sm">
                                    <b-icon-chevron-right></b-icon-chevron-right>
                                </b-button>
                            </b-row>
                        </template>

                        
                    </b-table>
                </b-col>
            </b-row>
            <b-row align-h="center" >
                    <b-pagination
                    v-model="productsTableData.currentPage"
                    :total-rows="productsTableData.rows"
                    :per-page="productsTableData.perPage"
                    ></b-pagination>
            </b-row>
            <b-row>
                <b-col cols="6">
                    <b-button variant="primary" squared @click="openMiscItemModal()" class="mr-2" :disabled="ogStatusSelection !== 1">Add Additional Item</b-button>
                </b-col>
                <b-col cols="6" class="text-right">
                    <b-button variant="red" @click="hideModal" class="mr-2 ml-2" squared>Close</b-button>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal id="addMiscItem-modal" hide-footer @close="closeMiscModal()" size="xl">
             <b-row>
                <b-col>
                    <h4>Add Product</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="4">
                    <label>Item Code</label>
                    <b-form-input v-model="miscProd.itemCode"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Product Reference</label>
                    <b-form-input v-model="miscProd.Reference"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Price Excl. Vat</label>
                    <b-form-input v-model="miscProd.priceExVat" type="number" step="any"  min="0"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button @click="addMiscItem" class="mr-2 ml-2" squared>Add</b-button>
                    <b-button variant="red" @click="hideMiscModal" class="mr-2 ml-2" squared>Close</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import router from "@/router";

export default {
    data: () => ({
        state: 'show',
        wasHandled: false,
        statusList: [],
        businessUnits: [],
        ogStatusSelection: null,
        typeList: [],
        emptyItem: {
            description: null,
            itemCode: null,
            price: null,
            quantity: null,
            budgetaryQuoteItemId: null,
            invoiceNumber: null,
            referenceNumber: null,
            relatedDocument: null
        },
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Item Code',
                    key: 'itemCode',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'Description',
                    key: 'description',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'Quantity',
                    key: 'quantity',
                    sortable: false,
                    tdClass: 'text-center',
                    thClass: 'text-center'
                },
                {
                    label: 'Price',
                    key: 'price',
                    sortable: false
                },
                {
                    label: 'VAT',
                    key: 'vatPerc',
                    sortable: false,
                    tdClass: 'align-items-center'
                },
                {
                    label: 'Total',
                    key: 'total',
                    sortable: false,
                    tdClass: 'text-right',
                    thClass: 'text-right'
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: 'text-right'
                }
            ]
        },
        search:{
            description: null,
            dateCreatedFrom: null,
            dateCreatedTo: null
        },
        miscProd:{
            itemCode: null,
            Reference: null,
            priceExVat: null
        },
        VatAmount: 0,
        productsTableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Item Code',
                    key: 'itemCode',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'Product Reference',
                    key: 'productRef',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'Date Created',
                    key: 'createdDate',
                    sortable: false
                },
                {
                    label: 'Price Excl. VAT',
                    key: 'priceExVat',
                    sortable: false,
                    tdClass: 'text-right',
                    thClass: 'text-right'
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: 'text-right'
                }
            ]
        }
    }),
    created(){
        // this.selectedQuote.budgetaryQuoteItems.push(this.emptyItem)
        this.tableData.dataSource = this.selectedQuote.budgetaryQuoteItems
        this.ogStatusSelection = this.selectedQuote.statusId
        //console.log(this.ogStatusSelection);

        window.addEventListener('keydown', (e) => {
            if (e.key == '+') {
                this.openAddItemModal()
            }
        });
        
        this.state = 'loadingData'
        this.getBusinessUnits()
        .then((res) => {
            this.businessUnits = res.data
        })
        
        this.getQuoteTypeList()
        .then((res) => {
            this.typeList = res.data
            this.getStatusList()
            .then((res) => {
                this.statusList = res.data
                this.statusOgList = res.data
                this.checkStatusList()
                this.checkStatusAuthList()
                 this.tableData.isLoading = false
                this.state = 'show'
            })
            .catch(() => {
                this.tableData.isLoading = false
                this.state = 'show'
            })
        })
        .catch(() => {
            this.tableData.isLoading = false
            this.state = 'show'
        })
        
    },
    methods:{
        ...mapActions(['downloadQuote', 'getStatusList', 'getQuoteTypeList', 'updateQuote', 'searchProducts', 'getBusinessUnits']),
        ...mapActions('notificationService', ['addWarningMessage']),
        addCommas(nStr)
        {
            nStr += '';
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ' ' + '$2');
            }
            return x1 + x2;
        },
      checkStatusAuthList(){
        //console.log("Checking Status")
        if (this.selectedQuote.statusId === 1) {
          this.statusList = this.statusList.filter((item) => item.description === 'Submitted' || item.description === 'Created' )
          //console.log("Status 1")
        } else if(this.selectedQuote.statusId === 5) {
          //console.log("Status 5")
          this.statusList = this.statusList.filter((item) => item.description !== 'Created' && item.description !== 'Expired' )
        } else {
          this.wasHandled = true
        }
      },
        checkStatusList(){
          //console.log("Checking Type")
            if (this.selectedQuote.quoteTypeId === 1) {
                this.selectedQuote.quoteNumber = "Quotation " + this.selectedQuote.budgetaryQuoteId
                this.statusList = this.statusOgList
            } else {
                this.selectedQuote.quoteNumber = "Budgetary Quotation " + this.selectedQuote.budgetaryQuoteId
                this.statusList = this.statusOgList.filter((item) => item.description !== 'Approved')
            }
            //console.log("quote",this.selectedQuote)
        },
        openAddItemModal(){
            this.$root.$emit('bv::show::modal', 'addItem-modal', '#btnShow')
            this.searchStock()
        },
        hideModal() {
            this.$root.$emit('bv::hide::modal', 'addItem-modal', '#btnShow')
        },
        openMiscItemModal(){
            this.miscProd.itemCode = null
            this.miscProd.Reference = null
            this.miscProd.priceExVat = null
            this.$root.$emit('bv::show::modal', 'addMiscItem-modal', '#btnMiscShow')
            this.searchStock()
        },
        hideMiscModal() {
            this.$root.$emit('bv::hide::modal', 'addMiscItem-modal', '#btnMiscShow')
        },
        addMiscItem()
        {
             let item = {
                active: true,
                budgetaryQuoteItemId: 0,
                description: this.miscProd.Reference,
                invoiceNumber: null,
                itemCode:  this.miscProd.itemCode,
                price:  this.miscProd.priceExVat,
                quantity: 1,
                referenceNumber:  this.miscProd.Reference,
                relatedDocument: null,
                vatIncluded: false,
                vatPerc: 15
            }
            this.selectedQuote.budgetaryQuoteItems.push(item)
            this.hideMiscModal()
            this.hideModal()
        },
        padResult(item){
            if (item !== undefined) {
                let newItem = item.toString().padStart(6, '0')
                return newItem
            } else{
                return 'N/A'
            }
        },
        download(){
            
            this.state = 'loading'
            //this.addWarningMessage('Downloading Quote')
            //save the quote first
            this.save(true).then((didsave) => {
                
                if (didsave)
                {
                    this.state = 'loading'
                    this.addWarningMessage('Downloading Quote')
                    
                    this.downloadQuote()
                    .then((res) => {
                        if (res.data !== '') {
                            const linkSource = `data:application/pdf;base64,${res.data.base64Data}`;

                            const downloadLink = document.createElement("a");
                            downloadLink.href = linkSource;
                            downloadLink.download = res.data.fileName;
                            downloadLink.click();
                           
                        }
                        else{
                            this.addWarningMessage('No data available to download')
                        }
                        this.state = 'show'
                    })
                    .catch(() => {
                        this.state = 'show'
                    })
                }
            });
            
            this.state = 'show'
        },
        totalLineItem(item){
            var t = item.price * item.quantity
            return this.addCommas(t.toFixed(2))
        },
        returnToPrevious(){
            this.$router.back()
        },
        removeStockItem(rowItem){
            this.tableData.isLoading = true
            for (let i = 0; i < this.selectedQuote.budgetaryQuoteItems.length; i++) {
                const element = this.selectedQuote.budgetaryQuoteItems[i];
                
                if (element.referenceNumber === rowItem.referenceNumber) {
                    this.selectedQuote.budgetaryQuoteItems.splice(i, 1)
                }
            }
                
            this.tableData.dataSource = this.selectedQuote.budgetaryQuoteItems
            this.tableData.isLoading = false
        },

        goHome(){
            setTimeout(() => {
            router.push('/quotes/search');
            }, "4000")

        },

        async save(forexport = false){
            
            if (this.selectedQuote.status =="Accepted" || this.selectedQuote.statusId == 4)
            {
                if (this.selectedQuote.purchaseOrderNumber === null || this.selectedQuote.purchaseOrderNumber === '') {
                    this.addWarningMessage('Purchase Order Number is a required field, please enter it to continue')
                    return false;
                }
            }
            if (this.selectedQuote.statusId === 2 && (this.selectedQuote.note === null || this.selectedQuote.note === '')) {
                this.addWarningMessage('This item has been marked as rejected, a reason for rejection needs to be provided')
            } else {
                this.state = 'loading'

                for (let i = 0; i < this.selectedQuote.budgetaryQuoteItems.length; i++) {
                    const element = this.selectedQuote.budgetaryQuoteItems[i];
                    element.vatPerc = parseFloat(element.vatPerc)
                }
                await this.updateQuote()

                .then(() => {
                    this.state = 'show'
                    if (!forexport){
                        this.returnToPrevious()
                    }
                })
                .catch(() => {
                    this.state = 'show'
                    return false;
                })
            }
            //console.log("Saved")
            return true;
        },
        searchStock(){
            this.productsTableData.isLoading = true
            
            let request = []
            if(this.search.description !== null){
                request.push({"key":"description","value": this.search.description})
            }
            if(this.search.dateCreatedFrom !== null){
                request.push({"key":"dateCreatedFrom","value": this.search.dateCreatedFrom})
            }
            if(this.search.dateCreatedTo !== null){
                request.push({"key":"dateCreatedTo","value": this.search.dateCreatedTo})
            }

            this.$store.commit('setProductSearchRequest', request)
            this.searchProducts()
            .then((res) => {
                //console.log(res)
                this.productsTableData.dataSource = res.data
                this.productsTableData.isLoading = false
            })
            .catch(() => {
                this.productsTableData.isLoading = false
            })
        },
        addStockItemRow(rowItem){
            let item = {
                active: rowItem.active,
                budgetaryQuoteItemId: 0,
                description: rowItem.productRef,
                invoiceNumber: null,
                itemCode: rowItem.itemCode,
                price: rowItem.priceExVat,
                quantity: 0,
                referenceNumber: rowItem.productRef,
                relatedDocument: null,
                vatIncluded: rowItem.includeVat,
                vatPerc: 15
            }
            this.selectedQuote.budgetaryQuoteItems.push(item)
            this.hideModal()
        },
        changeQuoteNumber(selection){
            let item = this.typeList.find(element => element.id === selection)
            this.selectedQuote.quoteNumber = item.description + ' ' + this.selectedQuote.budgetaryQuoteId
        },
        calcvat(){
            let total = 0
            if (this.selectedQuote.budgetaryQuoteItems !== null && this.selectedQuote.budgetaryQuoteItems !== undefined) {
                for (let i = 0; i < this.selectedQuote.budgetaryQuoteItems.length; i++) {
                    const element = this.selectedQuote.budgetaryQuoteItems[i];
                    // if (element.vatIncluded === true || element.vatIncluded === 'true') {
                        total += ((element.price * element.quantity) * (element.vatPerc /100))
                    // }
                }
            }
            return total
        },
    },
    computed:{
        ...mapState(['selectedQuote', 'isQuoteEditable']),
        subtotal(){
            let total = 0
            if (this.selectedQuote.budgetaryQuoteItems !== null && this.selectedQuote.budgetaryQuoteItems !== undefined) {
                
                for (let i = 0; i < this.selectedQuote.budgetaryQuoteItems.length; i++) {
                    const element = this.selectedQuote.budgetaryQuoteItems[i];
                    //console.log('element', element)
                    total += (element.price * element.quantity)
                }
            }
            return total
        },
        vat(){
            let total = 0
            if (this.selectedQuote.budgetaryQuoteItems !== null && this.selectedQuote.budgetaryQuoteItems !== undefined) {
                for (let i = 0; i < this.selectedQuote.budgetaryQuoteItems.length; i++) {
                    const element = this.selectedQuote.budgetaryQuoteItems[i];
                    // if (element.vatIncluded === 'true') {
                        total += ((element.price * element.quantity) * (element.vatPerc / 100))
                    // }
                }
            }
            return total
        },
        total(){
            return (parseFloat(this.subtotal) + parseFloat(this.vat))
        }
    }
}
</script>