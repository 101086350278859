<template>
    <div>
        <b-row class="h-100" v-if="state === 'initialize'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
        </b-row>
        
        <b-row v-if="state !== 'initialize'" class="mb-4">
            <b-col cols="12">
                <b-card>
                    <quoteView />
                </b-card>
            </b-col>
        </b-row>

        
        <b-modal id="result-modal" hide-footer no-close-on-backdrop @close="closeModal()">
            <template #modal-header="{}">
                <h5>Done!</h5>
            </template>
            <b-row>
                <b-col cols="12" class="text-center">
                    <p>Your Ticket number is as follows:</p>
                    <h3 class="text-center">{{solarWindsTicketNumber}}</h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-center">
                    <b-button variant="primary" @click="hideModal" class="mr-2 ml-2" squared>Done</b-button>
                </b-col>
            </b-row>
            <div class="d-block"></div>
        </b-modal>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import quoteView from '@/components/quotes/View.vue'

export default {
    components:{
        quoteView
    },
    data: () => ({
        state: 'initialize',
        request: {
            siteName: null,
            experiencingUser: null,
            reportingUser: {name: null}
        },
        solarWindsTicketNumber: null
    }),
    created(){
        if (this.selectedQuote === null) {
            this.$router.back()
        }
        this.setBreadcrumb([
        {
            text: 'Quote',
            to: {path: '/quotes/search'}
        },
        {
            text: 'View',
            active: true
        }
        ])
        this.getQuotes()
        .then(() => {
            this.state = 'show'
        })
    },
    methods:{
        ...mapActions(['getQuotes']),
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),
        showModal(){
            this.$root.$emit('bv::show::modal', 'result-modal', '#btnShow')
        },
        hideModal() {
            this.$root.$emit('bv::hide::modal', 'result-modal', '#btnShow')
            this.$router.push({path: '/quotes/search'})
        },
        save(){
        },
        goBackToSearch(){
            this.$router.back()
        }
    },
    computed:{
        ...mapState(['selectedQuote'])
    }
}
</script>